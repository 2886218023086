import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Address } from '../models/user/address.model';
import { User, UserPreferences } from '../models/user/user.model';
import { AddressesStore } from '../state/addresses.store';
import { CountryQuery } from '../state/country.query';
import { UserStore } from '../state/user.store';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private http = inject(HttpClient);
  private userStore = inject(UserStore);
  private authService = inject(AuthService);
  private addressesStore = inject(AddressesStore);
  private countryQuery = inject(CountryQuery);


  fillUserStore(): Observable<User | undefined> {
    return this.authService.token$.pipe(
      distinctUntilChanged(),
      switchMap(token => {
        if (token) {
          return this.http.get<User>(`${environment.apiUrl}/user`);
        }
          return of(undefined);

      }),
      tap(userdata => {
        this.userStore.update({ user: userdata});
      }));
  }

  fillAdressessStore(): Observable<Address[] | undefined> {
    return this.authService.token$.pipe(
      distinctUntilChanged(),
      switchMap(token => {
        if (token) {
          return this.http.get<Address[]>(`${environment.apiUrl}/user/addresses`);
        }
          return of(undefined);

      }),
      tap(addresses => {
        if (addresses) {
          this.addressesStore.set(addresses.filter(address => address.userAddress === false));
        }
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  register(user: User, password: string, token: string): Observable<User> {
    // TODO: Backend: check recaptcha token
    const extendedUser = ({
      ...user,
      password
    });
    return this.http.post<User>(`${environment.apiUrl}/register/user`, extendedUser).pipe(
      tap(response => {
        this.userStore.update({user: response});
        const deliveryAddres: Address = ({
          ...response.address,
          userAddress: false,
          deliveryAddress: true
        });
        this.authService.login(user.username, password).pipe(
          tap(() => {
            this.addAddress(deliveryAddres).subscribe(); // TODO: please check
          })
        ).subscribe();
      }),
      // catchError(this.handleError)
    );
  }

  addAddress(address: Address): Observable<Address> {
    return this.http.post<Address>(`${environment.apiUrl}/user/addresses`, address).pipe(
      tap(response => {
        this.addressesStore.add(response);
      }),
      // catchError(this.handleError)
    );
  }

  deleteAddress(address: Address): Observable<HttpResponse<void>> {
    return this.http.delete<void>(`${environment.apiUrl}/user/addresses/${address.id}`, { observe: 'response'}).pipe(
      tap(response => {
        if (response.status === 200) {
          this.addressesStore.remove(({ id }) => id === address.id);
        }
      }),
      // catchError(this.handleError)
    );
  }

  setDeliveryAddress(address: Address): Observable<Address> {
    this.addressesStore.update(({ id }) => id !== address.id, { deliveryAddress: false });
    return this.http.put<Address>(`${environment.apiUrl}/user/addresses/${address.id}`, address).pipe(
      tap(response => {
        this.addressesStore.update(({ id }) => id === response.id, { deliveryAddress: true});
      }),
      // catchError(this.handleError)
    );
  }

  updateInvoiceAddress(address: Address): Observable<Address> {
    if (address.countryCode !== null && (address.country === null || address.country === '')) {
      const country = this.countryQuery.getAll({
        filterBy: ({isoCode2}) => isoCode2 === address.countryCode
      });
      if (country && country.length === 1) {
        address.country = country[0].name;
      }
    }

    // elk adres als niet invoice markeren
    this.addressesStore.update(({ id }) => id !== address.id, { invoiceAddress: false });

    if (address.id === '') {
      // address aanmaken
      return this.http.post<Address>(`${environment.apiUrl}/user/addresses`, address).pipe(
        tap(response => {
          this.addressesStore.add(response);
        }),
        // catchError(this.handleError)
      );
    }
      // address updaten
      return this.http.put<Address>(`${environment.apiUrl}/user/addresses/${address.id}`, address).pipe(
        tap(response => {
          this.addressesStore.update(({ id }) => id === response.id, {
            ...response
          });
        }),
        // catchError(this.handleError)
      );


  }

  updateUserPreferences(user: User): Observable<User> {
    return this.http.put<UserPreferences>(`${environment.apiUrl}/user/preferences`, user.preferences).pipe(
      map(response => {
        const updatedUser: User = {
          ...user,
          preferences: response
        };
        this.userStore.update({user: updatedUser});
        return updatedUser;
      })
    );
  }

  updateUser(user: User, password: string): Observable<User> {
    const extendedUser = ({
      ...user,
      password
    });
    return this.http.put<User>(`${environment.apiUrl}/user`, extendedUser).pipe(
      tap( res => {
          this.userStore.update({ user: res});
        }
      ),
      // catchError(this.handleError)
    );
  }

  // handleError(error: any): Observable<any> {
  //   console.log('handleError hit: ', error);
  //   // TODO: proper error handling
  //   if (error.status === 0) {
  //     // A client-side or network error occurred. Handle it accordingly.
  //     console.error('An error occurred:', error.error);
  //   } else {
  //     // The backend returned an unsuccessful response code.
  //     // The response body may contain clues as to what went wrong.
  //     console.error(
  //       `Backend returned code ${error.status}, ` +
  //       `body was: ${error.error}`); // zorgen dat component juist afhandelt, info weergeeft
  //   }
  //   // Return an observable with a user-facing error message.
  //   return throwError(
  //     'Something bad happened; please try again later.');

  // }
}
